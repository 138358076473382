import { useActiveShop, useProfile } from '@tapestry/shared/hooks';
import {
  HeartbeatAppletFilters,
  HeartbeatComparisonPeriod,
  HeartbeatComparisonQueriesVariable,
  MeasureSlug,
  Twist,
  UserSetting,
} from '@tapestry/types';
import { dateTime, getTwistByKey } from '@tapestry/shared/utils';
import { useEffect, useMemo, useReducer } from 'react';
import { getDefaultComparison, getDefaultQueryParams } from '../../utils';
import { DEFAULT_COMPARISON_VARIABLE, DEFAULT_FILTERS } from '../../constants';
import { useQueryParams } from 'use-query-params';

type RealTimeAnalyticsState = {
  isReady: boolean;
  measure: MeasureSlug;
  filters: HeartbeatAppletFilters;
  filtersCount: number;
  scopeType: string;
  scopeId: string;
  sortBy: string;
  startDate: string;
  endDate: string;
  comparison: HeartbeatComparisonQueriesVariable;
  shopId?: string;
  groupId?: string;
};

type RealTimeAnalyticsAction =
  | { type: 'SET_FILTERS'; payload: HeartbeatAppletFilters }
  | { type: 'SET_IS_READY'; payload: boolean }
  | { type: 'SET_DATE_RANGE'; payload: { startDate: string; endDate: string } }
  | { type: 'SET_COMPARISON'; payload: HeartbeatComparisonQueriesVariable };

function realtimeAnalyticReducer(
  state: RealTimeAnalyticsState,
  action: RealTimeAnalyticsAction
) {
  switch (action.type) {
    case 'SET_FILTERS': {
      const filtersCount = Object.keys(action.payload || {}).length;

      return { ...state, filters: { ...action.payload }, filtersCount };
    }

    case 'SET_IS_READY': {
      return { ...state, isReady: action.payload };
    }

    case 'SET_DATE_RANGE': {
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    }

    case 'SET_COMPARISON': {
      return {
        ...state,
        comparison: action.payload,
      };
    }

    default:
      return state;
  }
}

const DAYS_IN_WEEK = 7;

const defaultFilters: HeartbeatAppletFilters = {
  isVoid: 'null',
};

const DEFAULT_DATE_RANGE = dateTime.getCurrentIsoWeek();

const REAL_TIME_ANALYTICS_INITIAL_STATE: RealTimeAnalyticsState = {
  isReady: false,
  measure: MeasureSlug.TotalSales,
  filters: DEFAULT_FILTERS,
  filtersCount: 0,
  scopeType: '',
  scopeId: '',
  sortBy: '',
  comparison: DEFAULT_COMPARISON_VARIABLE,
  ...DEFAULT_DATE_RANGE,
};

const useRealtimeAnalytics = () => {
  const activeShop = useActiveShop();
  const [profile, , { loading: isLoadingProfile }] = useProfile();

  const currentShopTimezone: string = getTwistByKey(
    Twist.Timezone,
    activeShop
  )?.value;

  const { startDate: defaultStart, endDate: defaultEnd } =
    dateTime.getCurrentIsoWeek(
      profile?.active_shop_scope?.timezone || currentShopTimezone
    );

  const heartbeatUserDefaultComparison = useMemo(
    () => getDefaultComparison(profile),
    [profile]
  );

  const [
    { startDate, endDate, shopId, groupId, slug: measureSlug, comparison },
    setQueryParams,
  ] = useQueryParams(
    getDefaultQueryParams({
      defaultStart,
      defaultEnd,
      defaultComparison: heartbeatUserDefaultComparison,
    })
  );

  const [state, dispatch] = useReducer(realtimeAnalyticReducer, {
    ...REAL_TIME_ANALYTICS_INITIAL_STATE,
    measure: measureSlug,
    shopId: shopId ?? activeShop?.id,
    groupId: groupId ?? undefined,
    comparison,
  });

  const isInitialising = useMemo(() => {
    if (!isLoadingProfile && startDate && endDate && comparison.key) {
      dispatch({
        type: 'SET_DATE_RANGE',
        payload: {
          startDate,
          endDate,
        },
      });

      dispatch({
        type: 'SET_COMPARISON',
        payload: comparison,
      });

      return false;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProfile, startDate, endDate, JSON.stringify(comparison)]);

  useEffect(() => {
    if (!isInitialising) {
      dispatch({
        type: 'SET_IS_READY',
        payload: true,
      });
    }
  }, [isInitialising]);

  const setDateRange = (startDate: string, endDate: string) => {
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: { startDate, endDate },
    });

    setQueryParams({ startDate, endDate });

    const periodLength = dateTime.diff(startDate, endDate, 'days') + 1;

    // TODO: create a function for this logic, it also get used in `resetDateRange`
    const shouldResetComparison =
      periodLength > DAYS_IN_WEEK ||
      state.comparison.value === HeartbeatComparisonPeriod.UserDefined;

    if (shouldResetComparison) {
      resetComparision();
    }
  };

  const resetDateRange = () => {
    dispatch({
      type: 'SET_DATE_RANGE',
      payload: { startDate: defaultStart, endDate: defaultEnd },
    });

    setQueryParams({ startDate: defaultStart, endDate: defaultEnd });

    const shouldResetComparison =
      state.comparison.value === HeartbeatComparisonPeriod.UserDefined;

    if (shouldResetComparison) {
      resetComparision();
    }
  };

  // TODO: handle setting default filters to user profile
  const setFilters = (filters: HeartbeatAppletFilters | undefined) => {
    dispatch({
      type: 'SET_FILTERS',
      payload: filters || defaultFilters,
    });
  };

  const resetFilters = () => {
    const userAnalyticFilters =
      profile?.user_settings?.[UserSetting.applet_analytic_filters];

    dispatch({
      type: 'SET_FILTERS',
      payload: userAnalyticFilters || defaultFilters,
    });
  };

  const setComparision = (comparison: HeartbeatComparisonQueriesVariable) => {
    dispatch({
      type: 'SET_COMPARISON',
      payload: comparison,
    });

    setQueryParams({ comparison });
  };

  const resetComparision = () => {
    dispatch({
      type: 'SET_COMPARISON',
      payload: heartbeatUserDefaultComparison,
    });

    setQueryParams({ comparison: heartbeatUserDefaultComparison });
  };

  return {
    data: { ...state, currentShopTimezone },
    setDateRange,
    resetDateRange,
    setFilters,
    resetFilters,
    setComparision,
    resetComparision,
  };
};

export {
  useRealtimeAnalytics,
  realtimeAnalyticReducer,
  REAL_TIME_ANALYTICS_INITIAL_STATE,
  DAYS_IN_WEEK,
};
