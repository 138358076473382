import { FC } from 'react';
import { ComparisonModal, FilterModal, ShopThreadHeader } from './components';
import { useModal } from '@tapestry/shared/hooks';
import { useRealtimeAnalytics } from './hooks/use-real-time-analytics';
import { THREAD_TYPE } from '@tapestry/types';
import { dateTime } from '@tapestry/shared/utils';

const ShopRealTimeAnalytics: FC = () => {
  const filterModal = useModal();
  const comparisonModal = useModal();

  const {
    data: {
      startDate,
      endDate,
      currentShopTimezone,
      filters,
      filtersCount,
      comparison,
      measure,
      groupId,
    },
    setFilters,
    resetFilters,
    setDateRange,
    resetDateRange,
    setComparision,
    resetComparision,
  } = useRealtimeAnalytics();

  // TODO: remove this mock handler once all handlers are implemented
  const mockHandler = () => {
    console.log('handle something');
  };

  const handleDateSelection = (period: {
    startDate: string;
    endDate: string;
  }) => {
    setDateRange(period.startDate, period.endDate);
  };

  return (
    <div className="min-h-screen bg-gray-100 pb-16">
      <ShopThreadHeader
        dateRangeSelectorProps={{
          startDate,
          endDate,
          currentShopTimezone,
          onReset: resetDateRange,
          onDateSelection: handleDateSelection,
        }}
        filterButtonProps={{
          activeFiltersCount: filtersCount,
          onClick: filterModal.open,
          onReset: resetFilters,
        }}
        comparisonButtonProps={{
          onClick: comparisonModal.open,
          onReset: resetComparision,
        }}
        onExportButtonClick={mockHandler}
        ellipsisItems={[]}
      />
      {/* <RealtimeWidgets /> */}

      {filterModal.isOpen ? (
        <FilterModal
          modalState={filterModal}
          filters={filters}
          onFilterSubmit={setFilters}
          handleSetAsDefaultFilters={mockHandler}
        />
      ) : null}

      {comparisonModal.isOpen && (
        <ComparisonModal
          modalState={comparisonModal}
          value={comparison}
          defaultComparison={comparison}
          onApply={setComparision}
          activeMeasureSlug={measure}
          activeThreadKind={THREAD_TYPE.SHOP}
          isGroupView={!!groupId}
          mainPeriodStartDayOfWeek={dateTime
            .convertToTimezone(
              startDate,
              currentShopTimezone,
              'YYYY-MM-DDTHH:mm:ssZ'
            )
            .day()}
          currentPeriodDiffInDays={dateTime.diff(startDate, endDate, 'days')}
          currentShopTimezone={currentShopTimezone}
        />
      )}
    </div>
  );
};

export { ShopRealTimeAnalytics };
