import { loadable } from '@tapestry/shared/lazy-load';
import { HeartbeatLoadingSplashScreen } from './lib/components';

export { Heartbeat } from './lib/heartbeat';
export { ShopRealTimeAnalytics } from './lib/ShopRealTimeAnalytics';

export default loadable(() => import('./lib/heartbeat'), {
  chunkName: 'heartbeat',
  fallbackComponent: <HeartbeatLoadingSplashScreen />,
});
